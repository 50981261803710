import { RetrieveNskTokenResponse } from "lib/common/definition";
// import { createNskToken } from "lib/common/service";
import { isAfter } from "date-fns";
import { ibeNskTokenExpireNotify } from "modules/features/flightBooking/actions";
import { mmbNskTokenExpireNotify } from "modules/features/manageMyBooking/actions";
import { Effect, call, cancel, delay, fork, put, race, select, take, takeLatest } from "redux-saga/effects";
import {
  resetNskToken,
  selectNskToken,
  storeNskToken,
  updateNskToken,
} from "store/sessionStorage/slices/nskTokenSlice";
import { CANCEL_INIT_NSK_TOKEN, INIT_NSK_TOKEN, NskTokenPayload, initSaga } from "../actions/nskTokenAction";

function* fetchNskToken(payload: NskTokenPayload): Generator<Effect, void, RetrieveNskTokenResponse> {
  // const response: RetrieveNskTokenResponse = yield call(createNskToken, payload.appCode);
  if (payload.access_token) {
    yield put(
      storeNskToken({
        nsk_token: payload.access_token,
        expiry_date: payload.expiry_date,
        app_code: payload.app_code,
        expiry_time: payload.expiry_time,
        count: payload.count,
      })
    );
    yield call(timeDelay, payload);
  } else {
    yield put({ type: "NSK_TOKEN-INIT_FAILED" });
  }
}

function* timeDelay(payload: NskTokenPayload): Generator<Effect, void, number & string> {
  const nskTokenExpireNotify = payload.app_code === "IBE" ? ibeNskTokenExpireNotify : mmbNskTokenExpireNotify;
  if (!payload.expiry_date) return;
  if (payload.count && payload.count < 0) return;
  // yield delay((expiryTime - 60) * 1000);
  // yield delay(5000);

  while (true) {
    yield delay(5000);
    if (isAfter(new Date(), new Date(payload.expiry_date))) {
      yield put(nskTokenExpireNotify(true));
      break;
    }
  }
}

export function* initNskToken(payload: NskTokenPayload): Generator<Effect, void, any> {
  yield put(initSaga());
  yield call(fetchNskToken, payload);
}

function* extendRace(payload: NskTokenPayload): Generator<Effect, void, any> {
  while (true) {
    const nskTokenExpireNotify = payload.app_code === "IBE" ? ibeNskTokenExpireNotify : mmbNskTokenExpireNotify;
    const { extend, close } = yield race({
      extend: take("NSK_TOKEN_EXTENDED"),
      close: take(["NSK_TOKEN-INIT_FAILED", "NSK_TOKEN_EXPIRED"]),
    });

    if (extend) {
      yield put(updateNskToken());
      yield put(nskTokenExpireNotify(false));
      const updatedNskToken = yield select(selectNskToken);

      yield call(timeDelay, updatedNskToken);
    } else if (close) {
      yield put(nskTokenExpireNotify(false));
      yield put(resetNskToken());
    }
  }
}

function* watchInitNskToken(action: any): Generator<Effect, void, any> {
  const task = yield fork(initNskToken, action.payload);
  const extendTokenTask = yield fork(extendRace, action.payload);
  yield take(CANCEL_INIT_NSK_TOKEN);
  yield put(resetNskToken());
  yield cancel(task);
  yield cancel(extendTokenTask);
}

export function* nskTokenSaga() {
  yield takeLatest(INIT_NSK_TOKEN, watchInitNskToken);
}
