import currency from "currency.js";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectCurrencyDetail } from "store/sessionStorage/slices/currency";

export function useCurrency() {
  const { selectedCurrency, bookingCurrency, rate, asiaMiles } = useSelector(selectCurrencyDetail);
  return {
    D: useCallback(
      (amount: string | number, from: string = bookingCurrency, to: string = selectedCurrency) => {
        const nAmount = parseFloat(amount + "");
        if (from === to) {
          return {
            toCurrency: to,
            fromCurrency: from,
            rate: 1,
            price: nAmount,
          };
        }
        const { exchange_rate = 1 } =
          rate.find((e) => e.from_currency_code === from && e.to_currency_code === to) ?? {};
        return {
          toCurrency: to,
          fromCurrency: from,
          rate: exchange_rate,
          originPrice: nAmount,
          price: nAmount ? nAmount * exchange_rate : 0,
        };
      },
      [bookingCurrency, rate, selectedCurrency]
    ),
    P: useCallback(
      (
        amount: string | number | undefined,
        forceCurrency: string | undefined = undefined,
        isString: boolean = false,
        forceRate: number = 0,
        formButton: boolean = false
      ) => {
        const isNaNResult = parseInt(amount + "");
        if (isNaN(isNaNResult)) return isNaNResult + "";

        const { exchange_rate = 1 } =
          rate.find((e) => e.to_currency_code === (forceCurrency ?? selectedCurrency)) ?? {};
        const price = amount ? parseFloat(amount + "") * (forceRate || exchange_rate) : 0;
        if (isString)
          return `${asiaMiles && forceCurrency === undefined ? `<i class='demo-icon icon-icon-asiamiles.originalimage-1'>&#xe800;</i>` : forceCurrency ?? selectedCurrency}${currency(
            price,
            { precision: price.toString().includes(".") ? 2 : 0 }
          ).format({
            symbol: " ",
            separator: ",",
            decimal: ".",
          })}`;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontWeight: formButton ? "600" : "500",
            }}
          >
            <div style={{ marginRight: "4px" }}>
              {asiaMiles && forceCurrency === undefined ? (
                <i className='demo-icon icon-icon-asiamiles.originalimage-1'>&#xe800;</i>
              ) : (
                forceCurrency ?? selectedCurrency
              )}
            </div>
            <div>
              {currency(price, { precision: price.toString().includes(".") ? 2 : 0 }).format({
                symbol: "",
                separator: ",",
                decimal: ".",
              })}
            </div>
          </div>
        );
      },
      [asiaMiles, rate, selectedCurrency]
    ),
  };
}
